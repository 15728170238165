import React, { useState } from 'react';
import { Box, Typography, AppBar, Toolbar, Link } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import FileUploader from './FileUploader';
import FileArchiever from './FileArchiever';

const FileTransfer = (props) => {
    return (
        <Grid2 container
            direction='row'
            alignItems="stretch"
            justifyContent='start'
            sx={{
                height: "100vh",
                margin: 0,
            }}
            columns={{
                xs: 4, lg: 12
            } }
        >
            <AppBar position='fixed'>
                <Toolbar>
                    <Box component='img' src='/images/logo.svg'></Box>

                    <Typography
                        align="right"
                        sx={{ fontSize: "20px", fontFamily: 'sans-serif', margin: '20px', flexGrow: 1 }}
                    >
                        <Link
                            underline="none"
                            href='http://robinseasky.org'
                            color='primary.contrastText'
                            target="_blank"
                            rel="noopener"

                        >
                            About Author
                        </Link>
                    </Typography>

                </Toolbar>
            </AppBar>
            <Grid2 xs={12}>
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize: "48px",
                        marginTop: '12vh',
                    }}
                    color='primary'
                >
                    Welcome to Robin's Fast File Transfer!
                </Typography>
            </Grid2>
            <Grid2 xs={12}>
                <Typography
                    sx={{
                        fontSize: "24px",
                        color: 'black',
                        textAlign: 'center',
                        marginBottom: '25px',
                    }}
                >
                    Upload and achieve files/text everywhere with 4-character code.
                </Typography>
            </Grid2>
            <Grid2 xs={7}>
                <FileUploader/>
            </Grid2>
            <Grid2 xs={5}>
                <FileArchiever />
            </Grid2>
        </Grid2>
    )
}

export default FileTransfer;