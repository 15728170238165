import React, { useState, useRef } from "react";
import { styled } from "@mui/styles";
import {
    Box, Button, Link, Typography,
    TextField, ToggleButton, ToggleButtonGroup,
    Select, MenuItem, Dialog, CircularProgress,
    SwipeableDrawer, List, ListItem, ListItemText, Grid,
    IconButton
} from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Grid2 from '@mui/material/Unstable_Grid2';
import FileAvatar from "./FileAvatar";

import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import HistoryIcon from '@mui/icons-material/History';
import RefreshIcon from '@mui/icons-material/Refresh';

import fetch from './myFetch';

const FILE = 0;
const TEXT = 1;

const NORMAL = 0;
const PROCESS = 1;
const FINISHED = 2;

const SUCESS = 3;
const PARTIAL = 4;
const ERROR = 5;


const ToggleButtonContrast = styled(ToggleButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&.Mui-selected': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    '&.Mui-selected:hover': {
        background: theme.palette.primary.light
    }
}));

const TextArea = styled(TextField)(({ theme }) => ({
    '&.MuiFormControl-root': {
        width: '100%',
        minWidth: '300px',
    },
    '& .MuiInputBase-root': {
        width: '100%',
        height: '210px',
        display: 'block',
    },
    '& textarea': {
        maxHeight: '100%',
        overflowY: 'scroll !important',
    },
}));

const DiaglogBox = styled(Box)(({ theme }) => ({
    width: '360px',
    height: '370px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
}));

const StyledTypo = styled(Typography)(({ theme }) => ({
    '&.title': {
        margin: '25px',
        fontSize: '32px',
    },
    '&.warning': {
        color: theme.palette.warning.light,
    },
    '&.success': {
        color: theme.palette.success.light,
    },
    '&.error': {
        color: theme.palette.error.light,
    },
}));

const ErrorMessageTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.error.main,
        boxShadow: theme.shadows[1],
    },
}));

const HistoryGrid = styled(({ xs=3, ...props }) => (
    <Grid item {...props} xs={xs} />
))(({ theme }) => ({
    fontSize: '18px',
    borderBottom: '1px solid black',
    textAlign: 'center',
    textTransform: 'uppercase',
    '&.title': {
        fontSize: '22px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    '&.InProcess': {
        color: theme.palette.info.main
    },
    '&.Failed': {
        color: theme.palette.error.main
    },
    '&.Deleted': {
        color: theme.palette.error.main
    },
    '&.PartialSucess': {
        color: theme.palette.warning.main
    },
    '&.Sucess': {
        color: theme.palette.success.main
    },
}));

const FileUploader = (props) => {

    const [currFiles, setCurrFiles] = useState([]);
    const [currText, setCurrText] = useState('');
    const [inputType, setInputType] = useState(FILE);
    const [validHours, setValidHours] = useState(3);
    const [uploadingStatus, setUploadingStatus] = useState({
        status: NORMAL
    });
    const [history, setHistory] = useState({
        show: false,
        historyUploads: [],
    })

    const inputRef = useRef(null);

    // parse uploading status
    const { status, total, successNum, errorMessages, code } = uploadingStatus;
    let resultStatus = status;
    if (status === FINISHED) {
        if (successNum === 0) {
            resultStatus = ERROR;
        }
        else if (successNum < total) {
            resultStatus = PARTIAL;
        } else {
            resultStatus = SUCESS;
        }
    }

    // parse history
    const { show: showHistory, historyUploads } = history;

    const addFile = (file) => {
        setCurrFiles((currFiles) => [...currFiles, file]);
    };

    const addFiles = (files) => {
        setCurrFiles((currFiles) => [...currFiles, ...files]);
    }

    const removeFile = (index) => {
        setCurrFiles((currFiles) => {
            currFiles.splice(index, 1);
            return [...currFiles];
        })
    }

    const uploadContents = async () => {
        if (inputType === FILE) {
            if (currFiles.length === 0) {
                alert("No file selected!")
                return;
            }

            setUploadingStatus({
                status: PROCESS,
                total: currFiles.length,
                successNum: 0
            });

            const errorMessages = [];

            const requestBody = {
                type: "File",
                validHours: validHours,
                fileNum: currFiles.length,
                files: currFiles.map((file) => {
                    return {
                        name: file.name,
                        size: file.size,
                    }
                })
            };

            const response = await fetch("/file/upload", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody)
            });

            // some error happens on the server
            if (!response.ok) {
                errorMessages.push(`[Server Error] ${response.status} ${await response.text() }`);
                setUploadingStatus({
                    status: FINISHED,
                    successNum: 0,
                    total: currFiles.length,
                    errorMessages: [...errorMessages],
                });
                return;
            }

            const { path, reports } = await response.json();

            const uploadResult = [...Array(reports.length)].map(() => false);

            await Promise.all(reports.map(async ({ sucess, presignedUrl, message }, index) => {
                if (!sucess) {
                    errorMessages.push(`<File Name> ${currFiles[index]?.name} <Error> ${message}`);
                    return;
                }
                const response = await fetch(presignedUrl, {
                    method: 'PUT',
                    body: currFiles[index]
                });

                // the s3 reject this upload
                if (!response.ok) {
                    errorMessages.push(`<File Name> ${currFiles[index]?.name} <Error> [S3 Error] ${response.status} ${await response.text()}`);
                    return;
                }

                uploadResult[index] = true;

                setUploadingStatus((uploadingStatus) => ({
                    ...uploadingStatus,
                    successNum: uploadingStatus.successNum + 1
                }));
            }));

            setUploadingStatus((uploadingStatus) => ({
                status: FINISHED,
                successNum: uploadingStatus.successNum,
                total: uploadingStatus.total,
                errorMessages: [...errorMessages],
                code: path
            }));

            // report the result - no need to wait for the result of this fetch
            fetch(`/file/uploadResults?code=${path}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(uploadResult)
            });

            setCurrFiles([]);
        }
        else {
            if (currText.length === 0) {
                alert("Please enter text")
                return;
            }

            setUploadingStatus({
                status: PROCESS,
                total: 1,
                successNum: 0
            });

            const errorMessages = [];

            const requestBody = {
                type: "Text",
                validHours: validHours,
                text: currText,
            };

            const response = await fetch("/file/upload", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody)
            });

            // some error happens on the server
            if (!response.ok) {
                errorMessages.push(`[Server Error] ${response.status} ${await response.text()}`);
                setUploadingStatus({
                    status: FINISHED,
                    successNum: 0,
                    total: 1,
                    errorMessages: [...errorMessages],
                });
                return;
            }

            const { path } = await response.json();

            setUploadingStatus((uploadingStatus) => ({
                status: FINISHED,
                successNum: 1,
                total: 1,
                errorMessages: [...errorMessages],
                code: path
            }));

            setCurrText("");
        }
    }

    const fetchHistory = async () => {
        const response = await fetch(`/file/history`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });

        if (!response.ok) {
            console.log(`[Server Error] ${response.status} ${await response.text() }`);
            return;
        }

        const { numInodes, metas } = await response.json();

        setHistory((history) => ({
            ...history,
            historyUploads : metas
        }));
    }

    const changeTextHandler = (e) => {
        setCurrText(e.target.value);
    }

    const uploadContentsHandler = (e) => {
        e.preventDefault();

        uploadContents();
    }

    const inputTypeChangeHandler = (event, newInputType) => {
        if (newInputType != null) {
            setInputType(newInputType);
        }
    };

    const addInputFileHandler = (event) => {
        addFiles(event.target.files);
        if (inputRef) {
            inputRef.current.value = '';
        }
    }

    const dropFilesHandler = (event) => {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            let files = [];
            for (var i = 0; i < event.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (event.dataTransfer.items[i].kind === 'file') {
                    let file = event.dataTransfer.items[i].getAsFile();
                    files = [...files, file]
                }
            }
            addFiles(files)
        }
    }

    const dragFilesHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const removeFileHandler = (index) => (event) => {
        event.preventDefault();
        removeFile(index);
    }

    const validHoursChangeHandler = (event) => {
        setValidHours(event.target.value);
    }

    const cleanContentsHandler = (event) => {
        setCurrText('');
        setCurrFiles([]);
    }

    const closeDialogHandler = (event) => {
        setUploadingStatus({
            status: NORMAL,
        })
    }

    const showHistoryHandler = (event) => {
        setHistory((history) => ({
            ...history,
            show: true,
        }));
        fetchHistory();
    }

    const hideHistoryHandler = (event) => {
        setHistory((history) => ({
            ...history,
            show: false,
        }));
    } 

    const refreshHistoryHandler = (event) => {
        fetchHistory();
    }

    return (
        <React.Fragment>
            <Grid2 container
                direction='column'
                alignItems='center'
                sx={{
                    position:'relative',
                    borderTop: '1px solid',
                    margin:0,
                }}
                spacing={3}
            >
                <Grid2>
                    <Box
                        sx={{
                            position: 'relative',
                            marginTop: '20px',
                        }}
                        
                    >
                        <Typography color="primary" sx={{
                            position: 'relative',
                            fontFamily: 'Crismon Pro',
                            fontSize: '48px',
                            zIndex: '10'
                        }}>
                            Upload
                        </Typography>
                        <Box sx={{
                            position: 'absolute',
                            backgroundColor: 'silver',
                            width: '90%',
                            height: '12%',
                            top: '72%',
                            left: '12%',
                        }}></Box>
                    </Box>
                </Grid2>
                <Grid2 sx={{
                    textAlign: 'center',
                    width: "80%",
                }}>
                    <ToggleButtonGroup
                        color="primary"
                        value={inputType}
                        exclusive
                        onChange={inputTypeChangeHandler}
                        size="large"
                    >
                        <ToggleButtonContrast sx={{ borderRadius: '15px 0px 0px 0px' }} value={FILE}>FILE</ToggleButtonContrast>
                        <ToggleButtonContrast sx={{ borderRadius: '0px 15px 0px 0px' }} value={TEXT}>TEXT</ToggleButtonContrast>
                    </ToggleButtonGroup>
                    {(inputType === FILE) &&
                        <Box component="div" >
                            <Button component='label' onDrop={dropFilesHandler} onDragOver={dragFilesHandler}
                                sx={{
                                    width: "100%",
                                    height: '210px',
                                    border: '1px dashed',
                                    borderRadius: '24px',
                                    textTransform: 'none'
                                }}
                            >
                                <input ref={inputRef} type='file' onChange={addInputFileHandler} multiple hidden />
                                {currFiles.length === 0 && <Typography
                                    sx={{
                                        fontSize: '20px',
                                    }}
                                >
                                    Drag files here or click to upload. (max size : 10GB)
                                </Typography>}
                                <Grid2 container
                                    flexDirection="row"
                                    alignItems="start"
                                    justifyContent="center"
                                    spacing="20px"
                                    sx={{
                                        maxHeight: "100%",
                                        overflowY: "scroll",
                                        "&::-webkit-scrollbar": {
                                            display: 'none',
                                        }
                                    } }
                                >
                                {currFiles.map((currFile, index) =>
                                    <Grid2 key={`upload-file-${index}`}>
                                        <FileAvatar filename={currFile.name} onDelete={ removeFileHandler(index)}/>
                                    </Grid2>
                                )}
                                </Grid2>
                            </Button>
                        </Box>
                    }
                    {(inputType === TEXT) &&
                        <Box component="div" >
                            <TextArea
                                multiline
                                variant="outlined"
                                value={currText}
                                onChange={changeTextHandler}
                                placeholder={'Type your text here...'}
                                focused
                            >
                            </TextArea>
                        </Box>
                    }
                </Grid2>
                <Grid2 sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}>
                    <Typography
                        sx={{
                            fontSize: '15px',
                            color: 'balck',
                        }}
                    >
                        perserve file(s) on server for 
                    </Typography>
                    <Select
                        value={validHours}
                        onChange={validHoursChangeHandler}
                        size='small'
                        sx={{
                            width: '120px',
                            fontSize: '15px',
                        } }
                    >
                        <MenuItem value={1}>1 hour</MenuItem>
                        <MenuItem value={3}>3 hours</MenuItem>
                        <MenuItem value={12}>12 hours</MenuItem>
                        <MenuItem value={24}>1 day</MenuItem>
                        <MenuItem value={72}>3 days</MenuItem>
                        <MenuItem value={168}>1 week</MenuItem>
                    </Select>
                </Grid2>
            
                <Grid2 sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}>
                    <Button
                        variant="outlined"
                        onClick={cleanContentsHandler}
                        startIcon={<DeleteIcon />}
                        sx={{
                            fontSize: '20px',
                        } }
                    >
                        Clean
                    </Button>
                    <Button
                        variant="contained"
                        onClick={uploadContentsHandler}
                        endIcon={<FileUploadIcon />}
                        sx={{
                            fontSize: '20px',
                        }}
                    >
                        Upload
                    </Button>
                </Grid2>
                <Button startIcon={<HistoryIcon />} onClick={showHistoryHandler}
                    sx={{
                        position: 'absolute',
                        top: 10,
                        left: '1vw',
                        fontSize: 25,
                        "& *:first-of-type": {
                            fontSize: 25
                        }
                    }}
                >
                    History
                </Button>
            </Grid2 >
            <Dialog open={resultStatus===PROCESS}>
                <DiaglogBox>
                    <StyledTypo
                        color='primary' className='title'>
                        Uploading...
                    </StyledTypo>
                    <Box sx={{
                        margin: '45px'
                    }}>
                        <CircularProgress size="80px" />
                    </Box>
                    <Typography color='primary'
                        sx={{
                            margin: '30px',
                            fontSize: '28px',
                        }}
                    >
                        {successNum} / {total} Succeed
                    </Typography>
                </DiaglogBox>
                
            </Dialog>
            <Dialog open={resultStatus === SUCESS}>
                <DiaglogBox>
                    <StyledTypo className={'success title'}>
                        Upload Success!
                    </StyledTypo>
                    <Typography
                        sx={{
                            marginBottom: '8px',
                            fontSize: '20px',
                            color: 'gray',
                        }}
                    >
                        Your code is:
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '50px',
                            textTransform: 'uppercase'
                        }}
                    >
                        {code}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontSize: '15px',
                            margin: '15px',
                            color: 'gray',
                            flexGrow: 1,
                        }}
                    >
                        You can now download files by visiting this page on target devices
                    </Typography>
                    <Button
                        variant="contained"
                        color='success'
                        onClick={closeDialogHandler}
                        sx={{
                            marginTop: '15px',
                            textTransform: 'none',
                            fontSize: '20px',
                        }}
                    >
                        Got it!
                    </Button>
                </DiaglogBox>
            </Dialog>
            <Dialog open={resultStatus === PARTIAL}>
                <DiaglogBox>
                    <StyledTypo className={'warning title'}>
                        Partially Success!
                    </StyledTypo>
                    <Typography
                        sx={{
                            marginBottom: '8px',
                            fontSize: '20px',
                            color: 'gray',
                        }}
                    >
                        Your code is:
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '50px',
                            textTransform: 'uppercase'
                        }}
                    >
                        {code}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                    }}>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '15px',
                                color: 'gray',
                            }}
                        >
                            {total - successNum} of {total} files' uplaod failed for those reasons:
                        </Typography>
                        <ErrorMessageTooltip title={
                            errorMessages?.map((errorMessage, index) => <Typography key={`error-${index}`} sx={{
                                fontSize: '15px',
                            }}>
                                {`${index+1}.${errorMessage}`}
                            </Typography>)
                        }>
                            <ErrorIcon color='error'/>
                        </ErrorMessageTooltip>
                    </Box>
                    <Button
                        variant="contained"
                        color='warning'
                        onClick={closeDialogHandler}
                        sx={{
                            marginTop: '15px',
                            textTransform: 'none',
                            fontSize: '20px',
                        }}
                    >
                        Got it!
                    </Button>
                </DiaglogBox>
            </Dialog>
            <Dialog open={resultStatus === ERROR}>
                <DiaglogBox>
                    <StyledTypo className={'error title'}>
                        Upload Failed!
                    </StyledTypo>
                    <SentimentVeryDissatisfiedIcon color='error' sx={{
                        fontSize: '100px'
                    }} />
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                    }}>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '15px',
                                margin: '15px',
                                color: 'gray',
                            }}
                        >
                            All files' uplaod failed for those reasons:
                        </Typography>
                        <ErrorMessageTooltip title={
                            errorMessages?.map((errorMessage, index) => <Typography key={`error-${index}`} sx={{
                                fontSize:'15px',
                            } }>
                                {`${index + 1}.${errorMessage}`}
                            </Typography>)
                        }>
                            <ErrorIcon color='error' />
                        </ErrorMessageTooltip>
                    </Box>
                    <Button
                        variant="contained"
                        color='error'
                        onClick={closeDialogHandler}
                        sx={{
                            textTransform: 'none',
                            fontSize: '20px',
                        }}
                    >
                        Got it!
                    </Button>
                </DiaglogBox>
            </Dialog>
            <SwipeableDrawer
                anchor='left'
                open={showHistory}
                onOpen={() => { } }
                onClose={hideHistoryHandler}
            >
                <Grid container
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="start"
                    sx={{
                        width: '400px',
                        overflow: 'hidden',
                    } }
                >
                    <Grid item xs={12} sx={(theme) => ({
                        background: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        textAlign: 'center',
                        height: '50px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'uppercase',
                    })}>

                        <Typography sx={{
                            fontSize: '25px',
                            flexGrow: 1,
                        } }>
                            Upload History
                        </Typography>
                         
                        <Tooltip title='refresh'>
                            <IconButton onClick={refreshHistoryHandler}>
                            <RefreshIcon sx={{
                                color: 'white',
                                fontSize: '30px'
                            }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <HistoryGrid className='title'>
                        code
                    </HistoryGrid>
                    <HistoryGrid className='title'>
                        status
                    </HistoryGrid>
                    <HistoryGrid className='title' xs={6}>
                        create time
                    </HistoryGrid>
                    {
                        historyUploads.reverse().map(({ code, status, isValid, createTime }, index) => {
                            const formatter = new Intl.DateTimeFormat('en-US',
                                {
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                });
                            const formattedDate = formatter.format(new Date(createTime));

                            if (!isValid) {
                                status = 'Deleted'
                            }

                            return (
                                <React.Fragment key={`history-${index}`}>
                                    <HistoryGrid>
                                        {code}
                                    </HistoryGrid>
                                    <HistoryGrid className={status}>
                                        {status}
                                    </HistoryGrid>
                                    <HistoryGrid xs={6}>
                                        {formattedDate}
                                    </HistoryGrid>
                                </React.Fragment>
                            )
                        })
                    }
                </Grid>
            </SwipeableDrawer>
        </React.Fragment>
    )
}

export default FileUploader;