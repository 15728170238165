import { ThemeProvider, createTheme } from '@mui/material/styles';
import FileTransfer from './FileTransfer';

const theme = createTheme({
    palette: {
        primary: {
            main: '#331DBF',
        },
        secondary: {
            main: '#9c27b0',
        },
    },
});

function App() {
  return (
      <ThemeProvider theme={theme}>
          <FileTransfer />
    </ThemeProvider>
  );
}

export default App;
