import React, { useEffect, useState } from "react";
import { Button, Link, Typography, Box, IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const FileAvatar = ({ filename, onDelete}) => {

    const shortenName = filename.length <= 10 ? filename : filename.slice(0, 7) + "...";

    return (
        <Box component="div"
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '5px'
            }}
        >
            <DescriptionIcon sx={{
                fontSize: "50px"
            }} />

            <Box component="div"
                sx={{
                    fontSize: "15px",
                    textDecoration: 'none',
                    textTransform: 'none',
                }}
            >
                {shortenName}
            </Box>
            <IconButton color='error' onClick={onDelete} size='small'
                sx={{
                    position: 'absolute',
                    top: -10,
                    right: 6,
                    zIndex: 5,
                }}
            >
                <DeleteForeverIcon />
            </IconButton>
        </Box>
    )
}

export default FileAvatar