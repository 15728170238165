import React, { useState, useEffect } from 'react';
import {
    Box, Typography, TextField, IconButton, Button,
    CircularProgress, Link, Fab
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import FileAvatar from './FileAvatar';

import fetch from './myFetch';

const INITIAL = 0;
const PROCESS = 1;
const FINISHED = 2;

const NONE = 0;
const FILE = 1;
const TEXT = 2;

const SearchingCodeInput = styled(({ ...props }) => (
    <TextField variant="outlined" focused {...props} />
))(({ theme }) => (
    {
        flexGrow: 1,
        '& .MuiInputBase-root': {
            borderRadius: 0,
            backgroundColor: theme.palette.common.white,
        },
        '& fieldset': {
            border: '0px',
        }
    }
));

const SearchingButton = styled(({ ...props }) => (
    <Button {...props} variant='contained'> <SearchIcon /> </Button>
))(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    boxShadow: 'none',
    '& svg': {
        color: theme.palette.common.white,
    }
}))

const CenterBox = styled(({ ...props }) => (
    <Box {...props} />
))(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const FileArchiever = (props) => {

    const [code, setCode] = useState('');
    const [fileStatus, setFileStatus] = useState({
        status: INITIAL,
    });
    const [showCodeError, setShowCodeError] = useState(false);

    const { status, currCode, type, text, files } = fileStatus;

    // when code changes, remove error message
    useEffect(() => {
        setShowCodeError(false);
    }, [code, setShowCodeError]);

    const archieveFiles = async (code) => {
        setFileStatus({
            currCode: code,
            status: PROCESS,
        });

        const response = await fetch(`/file/download?code=${code}`, {
            method: "GET",
        });

        if (!response.ok) {
            setFileStatus((fileStatus) => ({
                ...fileStatus,
                status: FINISHED,
                type: NONE,
            }));
            return;
        }

        const { type, ...rest } = await response.json();

        if (type === "File") {
            const { numFiles, files } = rest;

            if (numFiles <= 0) {
                setFileStatus((fileStatus) => ({
                    ...fileStatus,
                    status: FINISHED,
                    type: NONE,
                }));
                return;
            }

            setFileStatus((fileStatus) => ({
                ...fileStatus,
                status: FINISHED,
                type: FILE,
                files: files,
            }));
        }
        else {
            const { text } = rest;

            setFileStatus((fileStatus) => ({
                ...fileStatus,
                status: FINISHED,
                type: TEXT,
                text,
            }));
        }

    }

    const deleteFile = (index) => {
        const { currCode, files } = fileStatus;
        fetch(`/file/delete?code=${currCode}&id=${index}`, {
            method: "GET",
        });
        setFileStatus((fileStatus) => {
            files.splice(index, 1);
            return {
                ...fileStatus,
                files: [...files],
            }
        });
    }

    const deleteAllFiles = () => {
        const { currCode } = fileStatus;
        fetch(`/file/delete?code=${currCode}`, {
            method: "GET",
        });
        setFileStatus((fileStatus) => ({
            ...fileStatus,
            files: [],
            text: "",
        }));
    }

    const changeCodeHandler = (e) => {
        setCode(e.target.value);
    }

    const archieveFilesHandler = (e) => {
        if (!(code && /^[0-9a-zA-Z]{4}$/.test(code))) {
            setShowCodeError(true);
            return;
        }
        archieveFiles(code)
    }

    const deleteFileHandler = (index) => (e) => {
        e.preventDefault();
        e.stopPropagation()
        deleteFile(index);
    }

    const deleteAllFilesHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        deleteAllFiles();
    }

    return (
        <React.Fragment>
            <Grid2 container
                direction='column'
                alignItems='center'
                style={{
                    minHeight: '72vh',
                    borderTop: '1px solid',
                    borderLeft: '1px solid',
                    backgroundColor: '#EEE',
                    margin: '0px',
                }}
                spacing={3}
            >
                <Grid2>
                    <Box
                        sx={{
                            position: 'relative',
                            marginTop: '20px',
                        }}
                    >
                        <Typography color="primary" sx={{
                            position: 'relative',
                            fontFamily: 'Crismon Pro',
                            fontSize: '48px',
                            zIndex: '1'
                        }}>
                            Acquire
                        </Typography>
                        <Box sx={{
                            position: 'absolute',
                            backgroundColor: 'white',
                            width: '90%',
                            height: '12%',
                            top: '72%',
                            left: '10%',
                        }}></Box>
                    </Box>
                </Grid2>
                <Grid2 xs={6} sx={{
                    position: 'relative',
                }}>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <SearchingCodeInput
                            onChange={changeCodeHandler}

                            placeholder="Please enter your code here" />
                        <SearchingButton onClick={archieveFilesHandler} />
                        {showCodeError && < Typography sx={{
                            position: 'absolute',
                            width: '100%',
                            textAlign: 'center',
                            left: 0,
                            top: "75px",

                        }} color='error'>
                            *code should be of length 4 and includes only digits and albert characters
                        </Typography>}
                    </Box>
                </Grid2>
                <Grid2
                    xs={8}
                    sx={{
                        height: '300px',
                        marginTop: 5,
                        backgroundColor: 'white',
                    }}
                >
                    {status === PROCESS && <CenterBox>
                        <CircularProgress size='80px' />
                    </CenterBox>}
                    {status === FINISHED && type === NONE && <CenterBox>
                        < Typography sx={{
                            fontSize: '25px',
                            fontStyle: 'italic',
                        }}
                            color='error' >
                            Code Not Found or Files Deleted.
                        </Typography>
                    </CenterBox>}
                    {status === FINISHED && type === FILE && <Grid2 container
                        flexDirection='row'
                        justifyContent='start'
                        sx={{
                            paddingTop: '2px',
                            width: '100%',
                            height: '100%',
                            overflowY: "scroll",
                        }}
                    >
                        {
                            files.filter(({ presignedUrl }) => presignedUrl).map(({ name, presignedUrl }, index) =>

                                <Grid2 key={`file-${index}`}>
                                    <Link href={presignedUrl}>
                                        <FileAvatar filename={name} onDelete={deleteFileHandler(index)} />
                                    </Link>
                                </Grid2>
                            )
                        }
                    </Grid2>}
                    {status === FINISHED && type === TEXT && <Typography
                        sx={{
                            paddingTop: '2px',
                            width: '100%',
                            height: '100%',
                            overflowY: "scroll",
                        }}
                    >
                        {text.split('\n').map((line, index) => <React.Fragment key={`line-${index}` }> {line}  <br /> </React.Fragment> )}
                    </Typography>}
                    {status === FINISHED && type !== NONE &&
                        <CenterBox sx={{
                            height: 'auto',
                            position: 'relative',
                            top: '-60px',
                            zIndex: '10',
                        }}>
                            <Fab variant="extended" color='error' onClick={deleteAllFilesHandler }>
                                <DeleteForeverIcon />
                                Clear All
                            </Fab>
                        </CenterBox>
                    }
                </Grid2>
            </Grid2>
        </React.Fragment>
    );
}

export default FileArchiever;
