const rootUrl = process.env.NODE_ENV === 'production' ? 'https://file-backend.robinseasky.org' : ''

const myFetch = (endpoint, config = {}, ...options) => {
	if (endpoint[0] === '/') {
		endpoint = endpoint.slice(1);
		const url = `${rootUrl}/${endpoint}`;

		const newConfig = {
			...config,
			credentials: 'include',
		} 

		return fetch(url, newConfig, ...options);
	}
	else {
		return fetch(endpoint, config, ...options);
	}
};

export default myFetch;